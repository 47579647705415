import { StyledSubmitButton } from "components/utils/buttons";
import { FormWrapper } from "components/utils/form.styles";
import { Input } from "components/utils/inputs";
import { CustomSelect } from "components/utils/select";
import { useCountryStateCity } from "hooks/address";
import { useEffect, useState } from "react";

export const Profile = (props) => {
  const { getCities, getCountries, getStates } = useCountryStateCity();
  const handleClick = () => {
    console.log("clicked");
  };

  const [personal, setPersonal] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    ...props.personal,
  });

  const [address, setAddress] = useState({
    country: null,
    state: null,
    city: null,
    address1: "",
    address2: "",
    postalCode: "",
    ...props.address,
  });

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      const countries = await getCountries();
      setCountries(countries);
    };
    if (countries.length === 0) {
      fetchCountries();
    }
  }, [countries.length, getCountries, setCountries]);

  const updateCountry = async (value) => {
    const country = countries.find((country) => country.name === value);
    setAddress({ ...address, state: null, city: null, country: country });
    const states = await getStates(country?.iso2);
    setCities([]);
    setStates(states);
  };

  const updateState = async (value) => {
    const state = states.find((state) => state.name === value);
    setAddress({ ...address, city: null, state: state });
    const cities = await getCities(address.country?.iso2, state?.iso2);
    setCities(cities);
  };

  const updateCity = (value) => {
    const city = cities.find((city) => city.name === value);
    setAddress({ ...address, city: city });
  };

  return (
    <>
      <FormWrapper
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          handleClick();
        }}
      >
        <h2>My personal Information</h2>
        <Input placeholder='First Name' type='text' />
        <Input placeholder='Last Name' type='text' />
        <Input placeholder='Email' type='email' disabled={true} />
        <Input placeholder='Phone Number' type='phone' />
        <StyledSubmitButton type='submit'>Save</StyledSubmitButton>
      </FormWrapper>
      <FormWrapper
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          handleClick();
        }}
      >
        <h2>My Address</h2>
        <CustomSelect
          label='Select a Country first'
          options={countries?.map((country) => country.name)}
          value={address.country?.name}
          onChange={async (value) => await updateCountry(value)}
        />
        <CustomSelect
          label={
            states?.length > 0 ? "Select a state first" : "No States found"
          }
          options={states?.map((state) => state.name) || []}
          value={address.state?.name}
          disabled={states.length === 0}
          onChange={async (value) => await updateState(value)}
        />
        <CustomSelect
          label={cities?.length > 0 ? "Select a city" : "No Cities found"}
          options={cities?.map((city) => city.name) || []}
          value={address.city?.name}
          disabled={cities.length === 0}
          onChange={updateCity}
        />
        <Input placeholder='Address 1' type='text' />
        <Input placeholder='Address 2' type='text' />
        <Input placeholder='Postal Code' type='text' />
        <StyledSubmitButton type='submit'>Save</StyledSubmitButton>
      </FormWrapper>
    </>
  );
};
