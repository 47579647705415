import "menu.css";
import styled from "styled-components";
import { VscAccount } from "react-icons/vsc";
import { BsInbox } from "react-icons/bs";
import { CiSettings } from "react-icons/ci";
import { BiHelpCircle } from "react-icons/bi";
import { CiLogout } from "react-icons/ci";

import React, { useEffect, useRef, useState } from "react";
import { Constants } from "config/constants";
import { usePlatformContext } from "config/context";
import { StyledHeader } from "components/utils/text";
import { StyledSubmitButton } from "components/utils/buttons";
import { useNavigate } from "react-router";
const colors = Constants.theme.colors;

const MenuContainer = styled.div`
  .active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: transform 0.3s ease;
  }

  .inactive {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  border: 1px solid ${colors.mediumGrey};
  top: ${(props) => props.top && "100px"};
  bottom: ${(props) => props.bottom && "80px"};
  left: ${(props) => (props.bottom ? "20px" : props.position + "px")};
  z-index: 1000;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 20px;
  width: 200px;

  h1 {
    font-size: 1rem;
  }
`;

const DropDownList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .logout {
    background-color: red;
    color: ${colors.white};

    svg {
      color: ${colors.white};
    }

    &:hover {
      background-color: red;
      opacity: 0.9;
    }

    &:active {
      background-color: red;
      opacity: 0.8;
    }
  }
`;

const DropdownItemWrapper = styled(StyledSubmitButton)`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 0;
  margin-top: 10px;
  border-radius: 5px;
  color: ${colors.darkGrey};
  font-size: 0.9rem;
  height: auto;
  padding: 10px 20px;

  svg {
    margin-right: 10px;
    color: ${colors.charcoalGrey};
  }

  background-color: ${colors.lightGrey};

  &:hover {
    background-color: ${colors.lightGrey};
  }

  &:active {
    background-color: ${colors.lightGrey};
    border: none;
    opacity: 0.6;
  }
`;

function DropdownItem(props) {
  const navigate = useNavigate();
  return (
    <DropdownItemWrapper
      {...props}
      onClick={() => {
        if (props.to) {
          navigate("/" + props.to);
        } else {
          props.onClick();
        }
      }}
    >
      {props.img}
      {props.text}
    </DropdownItemWrapper>
  );
}

export default function Menu() {
  const context = usePlatformContext();
  let menuRef = useRef();
  let topNavIcon = document.getElementById("top-nav-icon");
  const [position, setPosition] = useState(
    topNavIcon?.getBoundingClientRect().left - 150
  );

  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        context.setMenu(false);
      }
    };

    let positionHandler = () => {
      console.log(topNavIcon?.getBoundingClientRect().left);
      setPosition(topNavIcon?.getBoundingClientRect().left - 100);
    };

    document.addEventListener("mousedown", handler);

    window.addEventListener("resize", positionHandler);

    return () => {
      document.removeEventListener("mousedown", handler);
      window.removeEventListener("resize", positionHandler);
    };
  });

  return (
    <MenuContainer ref={menuRef}>
      <DropdownMenu
        className={context.menu.state ? "active" : "inactive"}
        top={context.menu.location === "top"}
        bottom={context.menu.location === "bottom"}
        position={position}
      >
        <StyledHeader>
          Welcome, <br />
          {context.user.Email}
        </StyledHeader>
        <hr />
        <DropDownList>
          <DropdownItem
            img={<VscAccount />}
            text={"My Profile"}
            to={"profile#0"}
          />
          <DropdownItem img={<BsInbox />} text={"Chat"} to={"chat"} />
          <DropdownItem
            img={<CiSettings />}
            text={"Settings"}
            to={"profile#1"}
          />
          <DropdownItem
            img={<BiHelpCircle />}
            text={"Help"}
            onClick={() => {
              context.redirect("https://bestgrowth.io/faq");
            }}
          />
          <DropdownItem
            img={<CiLogout />}
            text={"Logout"}
            className={"logout"}
            onClick={() => {
              context.setLoading(true);
              context.api.logout();
            }}
          />
        </DropDownList>
      </DropdownMenu>
    </MenuContainer>
  );
}
