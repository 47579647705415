import { OrdersLayer } from "components/sections/table/orders";

function Orders() {
  return (
    <div>
      <OrdersLayer />
    </div>
  );
}

export default Orders;
