import LogoImg from "assets/logo/logo.svg";
import SmallImg from "assets/logo/small.png";
import styled from "styled-components";
import { StyledLink } from "./text";

export const LogoContainer = styled(StyledLink)`
  text-decoration: none;
  cursor: pointer;
  padding: 0;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }

  img {
    width: 100%;
    max-width: 10rem;
    min-width: 100px;
  }

  .small-logo {
    display: ${(props) => (props.small ? "block" : "none")};
    max-width: 50px;
    min-width: 0px;

    @media (max-width: 768px) {
      display: ${(props) => (props.sidebar ? "block" : "none")};
    }
  }

  .big-logo {
    display: ${(props) => (props.small ? "none" : "block")};

    @media (max-width: 768px) {
      display: ${(props) => (props.sidebar ? "none" : "block")};
    }
  }

  h1 {
    font-size: 1%.8;
    font-weight: 700;
    color: black;
  }
`;

export const Logo = (props) => {
  return (
    <LogoContainer
      to={"/"}
      reloadDocument={true}
      className='logo'
      small={props.small}
      sidebar={props.sidebar}
    >
      <img src={LogoImg} alt='logo' className='big-logo' />
      <img src={SmallImg} alt='logo' className='small-logo' />
    </LogoContainer>
  );
};
