import styled from "styled-components";
import { Constants } from "config/constants";
import { SearchField } from "components/utils/search";

const colors = Constants.theme.colors;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 8px;
  background-color: ${colors.white};
  border: 1px solid ${colors.mediumGrey};
  margin-bottom: 20px;
  width: 100%;

  .wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    justify-content: flex-end;
  }
`;

export const TableFilter = (props) => {
  return (
    <Wrapper>
      <SearchField
        data={props.data}
        setter={props.setter}
        keys={["name", "amount"]}
      />
      {props.options && (
        <div className='wrapper'>
          {props.options.map((option, index) => {
            return (
              <div key={index} className='option'>
                {option}
              </div>
            );
          })}
        </div>
      )}
    </Wrapper>
  );
};
