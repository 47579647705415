import { StyledIconButton } from "components/utils/buttons";
import { RiAccountCircleLine } from "react-icons/ri";
import { HeaderElement } from "components/layout/layout.styles";
import { usePlatformContext } from "config/context";
import { StyledHeader, StyledParagraph } from "components/utils/text";
import { Logo } from "components/utils/logo";
import { BsChatLeft } from "react-icons/bs";
import { useNavigate } from "react-router";

export const Header = (props) => {
  const context = usePlatformContext();
  const navigate = useNavigate();
  return (
    <HeaderElement justify={"space-between"}>
      <HeaderElement>
        <div className='header-wrapper'>
          <StyledHeader className='title'>
            {"Manage your Projects"}
          </StyledHeader>
          <StyledParagraph>
            Track your projects, tasks & team activity here
          </StyledParagraph>
        </div>
        <Logo />
      </HeaderElement>
      <HeaderElement flex={1} justify={"end"}>
        <StyledParagraph className='credits'>80$</StyledParagraph>
        <StyledIconButton
          id='top-nav-icon'
          onClick={() => {
            navigate("chat");
          }}
        >
          <BsChatLeft />
        </StyledIconButton>
        <StyledIconButton
          id='top-nav-icon'
          onClick={() => {
            navigate("profile");
          }}
        >
          <RiAccountCircleLine />
        </StyledIconButton>
      </HeaderElement>
    </HeaderElement>
  );
};
