import { StyledSubmitButton } from "components/utils/buttons";
import { Card } from "components/utils/cards";
import { FormInput } from "components/utils/form.styles";
import { Container } from "components/utils/wrapper";
import {
  StyledHeader,
  StyledLink,
  StyledParagraph,
} from "components/utils/text";
import { Constants } from "config/constants";
import { usePlatformContext } from "config/context";
import { useState } from "react";

const colors = Constants.theme.colors;

const WelcomeBlock = (props) => {
  return (
    <>
      <StyledHeader
        fontSize={"1.5rem"}
        textAlign={"start"}
        color={colors.primary}
      >
        Welcome to Bestgrowth! 🚀
      </StyledHeader>
      <StyledParagraph
        margin={"0.8rem 0"}
        textAlign='start'
        color={colors.charcoalGrey}
        fontSize={"1rem"}
      >
        We're stoked to have you here, checking out our platform before anyone
        else gets to see it. You're a pioneer, the kind that loves to lead the
        way and push boundaries. <br />
        <br /> We're creating a platform that will revolutionize the way
        promotions are done. Less time chatting, more time influencing. Sounds
        good? That's just the tip of the iceberg.
        <br />
        <br />
        Here's an exclusive chance for you to be a part of this revolution from
        the get-go. Click on the "Pre-Signup for Beta" button to join the ride
        and get VIP access to our platform. This will take you to a quick form,
        and once that's done, we'll usher you into our exclusive Discord server
        where you can connect with other pioneers like you.
        <br />
        <br />
        Remember, the future is what we make of it, and we're thrilled you're
        choosing to make it with us. Let's get this show on the road! 🎉
      </StyledParagraph>
      <StyledSubmitButton
        onClick={() => {
          //TODO: Add link to form
          props.redirect(process.env.REACT_APP_FORM_LINK);
        }}
      >
        Pre-Signup for Beta
      </StyledSubmitButton>
      {/* <StyledLink
        onClick={props.toggle}
        textAlign='center'
        color={colors.charcoalGrey}
      >
        Do you have a code to redeem access? Click here
      </StyledLink> */}
    </>
  );
};

const RedeemBlock = (props) => {
  const [values, setValues] = useState({ code: "" });
  const [ready, setReady] = useState({
    code: false,
  });
  const [check, setCheck] = useState(false);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  function handleClick() {
    setCheck(true);
    if (ready.code) {
      props.handleSubmit(values);
    }
  }
  return (
    <>
      <StyledHeader
        fontSize={"1.5rem"}
        textAlign={"start"}
        color={colors.primary}
      >
        Welcome to Bestgrowth! 🚀
      </StyledHeader>
      <StyledParagraph
        margin={"0.8rem 0"}
        textAlign='start'
        color={colors.charcoalGrey}
        fontSize={"1rem"}
      >
        Please enter your personal Code from the message we sent you on
        Instagram to redeem access to our pre signup.
      </StyledParagraph>
      <FormInput
        onChange={handleChange}
        ready={ready}
        defaultValue={values.password}
        name='code'
        setReady={setReady}
        check={check}
        placeholder='Enter your code to redeem access'
        type='text'
        pattern='^(?!\s*$).+'
        error={"Please enter your code"}
      />
      <StyledSubmitButton
        margin={"0.8rem 0"}
        type='submit'
        onClick={handleClick}
      >
        {props.failed ? "Retry" : "Redeem now"}
      </StyledSubmitButton>
      <StyledLink
        onClick={props.toggle}
        textAlign='center'
        color={colors.charcoalGrey}
      >
        Don't have a code? Click here
      </StyledLink>
    </>
  );
};

const SuccessBlock = (props) => {
  return (
    <>
      <StyledHeader
        fontSize={"1.5rem"}
        textAlign={"start"}
        color={colors.primary}
      >
        You officially joined the Beta 🚀
      </StyledHeader>
      <StyledParagraph
        margin={"0.8rem 0"}
        textAlign='start'
        color={colors.charcoalGrey}
        fontSize={"1rem"}
      >
        You can join the community now! 🎉 <br />
        We will give you your personal role in the Discord server as soon as
        possible after you joined. <br />
        <br />
        See you there! 👋
      </StyledParagraph>
      <StyledSubmitButton
        onClick={() => {
          props.redirect(process.env.REACT_APP_DC_INVITE);
        }}
      >
        Join Discord
      </StyledSubmitButton>
    </>
  );
};

function Welcome() {
  const [redeem, setRedeem] = useState(false);
  const toggle = () => setRedeem(!redeem);
  const context = usePlatformContext();
  const [failed, setFailed] = useState(false);

  const handleSubmit = (values) => {
    context.api.accessPreSignup(values.code).then((data) => {
      if (!data.status) setFailed(true);
    });
  };

  return (
    <Container>
      <Card justifyContent={"space-between"} maxWidth={"600px"}>
        {context.user.attribute.isBetaMember ? (
          <SuccessBlock redirect={context.utils.redirect} />
        ) : redeem ? (
          <RedeemBlock
            toggle={toggle}
            handleSubmit={handleSubmit}
            failed={failed}
          />
        ) : (
          <WelcomeBlock toggle={toggle} redirect={context.utils.redirect} />
        )}
      </Card>
    </Container>
  );
}

export default Welcome;
