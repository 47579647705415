import { CustomSidebarLink, StyledIconButton } from "components/utils/buttons";
import { Logo } from "components/utils/logo";
import { Constants } from "config/constants";
import styled from "styled-components";
import {
  AiOutlineCloseCircle,
  AiOutlineMenu,
  AiOutlineInstagram,
} from "react-icons/ai";
import { FiHome, FiPackage } from "react-icons/fi";
import { BiShoppingBag } from "react-icons/bi";
import { HiArrowNarrowRight } from "react-icons/hi";
import { usePlatformContext } from "config/context";
import { MdPayment } from "react-icons/md";

const colors = Constants.theme.colors;

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${(props) => (props.open ? "300px" : "100px")};
  width: 100%;
  flex: 1;
  background-color: ${colors.white};
  border-right: 1px solid ${colors.mediumGrey};

  @media (max-width: 768px) {
    max-width: 100px;
  }

  @media (max-width: 500px) {
    display: none;
  }
`;

const SidebarHeader = styled.div`
  width: 100%;
  padding: ${(props) => (props.open ? "1rem" : "1.7rem")};
  display: flex;
  height: 130px;
  flex-direction: ${(props) => (props.open ? "row" : "column")};
  justify-content: ${(props) => (props.open ? "space-between" : "center")};
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    padding: 1.7rem;
  }

  .close {
    display: ${(props) => (props.open ? "block" : "none")};

    @media (max-width: 768px) {
      display: none;
    }
  }
`;

const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  padding: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 100px);
  align-items: center;

  .open {
    display: ${(props) => (props.open ? "none" : "flex")};
    color: #ef6262;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .active {
    .title {
      font-weight: 700;
      color: ${colors.primary};
    }
    svg {
      color: ${(props) => !props.open && colors.primary};
    }

    @media (max-width: 768px) {
      svg {
        color: ${(props) => !props.open && colors.primary};
      }
    }
  }
`;

const SidebarFooter = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
`;

const SidebarElement = styled(CustomSidebarLink)`
  text-decoration: none;
  color: black;
  display: flex;
  flex-direction: ${(props) => (props.open ? "row" : "column")};
  align-items: center;
  padding: ${(props) => (props.open ? "1rem" : "0.8rem")};
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  width: 100%;

  @media (max-width: 768px) {
    padding: 0.5rem;
    max-width: 50px;
    flex-direction: column;
  }

  .title {
    font-size: 1.1rem;
    font-weight: 500;
    display: ${(props) => (props.open ? "block" : "none")};

    @media (max-width: 768px) {
      display: none;
    }
  }

  svg {
    font-size: 1.6rem;
    margin-right: ${(props) => (props.open ? "1rem" : "0")};

    @media (max-width: 768px) {
      margin-right: 0;
    }
  }

  &:hover {
    background-color: ${colors.lightGrey};
  }

  &:active {
    background-color: ${colors.mediumGrey};
  }
`;

export const Sidebar = () => {
  const context = usePlatformContext();
  let open = false;
  const locations = ["/", "/offers", "/pages", "/orders", "/payout"];
  const names = ["Home", "Offers", "Pages", "Orders", "Payout"];
  const icons = [
    <FiHome />,
    <FiPackage />,
    <AiOutlineInstagram />,
    <BiShoppingBag />,
    <MdPayment />,
  ];
  return (
    <SidebarContainer open={open}>
      <SidebarHeader open={open}>
        <Logo small={!open} sidebar={true} />
        <StyledIconButton onClick={context.toggleSidebar} className='close'>
          <AiOutlineCloseCircle />
        </StyledIconButton>
      </SidebarHeader>
      <SidebarContent open={open}>
        {locations.map((loc, i) => (
          <SidebarElement
            open={open}
            to={loc}
            className={window.location.pathname === loc && "active"}
          >
            {icons[i]}
            <span className='title'>{names[i]}</span>
          </SidebarElement>
        ))}
      </SidebarContent>
      <SidebarFooter>
        <SidebarElement
          open={open}
          onClick={() => context.toggleMenu("bottom")}
        >
          <AiOutlineMenu />
          <span className='title'>More</span>
        </SidebarElement>
      </SidebarFooter>
    </SidebarContainer>
  );
};
