import axios from "axios";

export const useCountryStateCity = () => {
  const instance = axios.create({
    baseURL: "https://api.countrystatecity.in/v1/",
    headers: {
      "X-CSCAPI-KEY":
        "MEZBQ0NCSVN0TUJZTGs4TnluYWZnWnZiVHNkUlNPa01lMDl4T2VXSg==",
    },
  });

  const getCountries = async () => {
    const response = await instance.get("countries");
    return response.data;
  };

  const getStates = async (countryId) => {
    const response = await instance.get(`countries/${countryId}/states`);
    return response.data || [];
  };

  const getCities = async (countryId, stateId) => {
    const response = await instance.get(
      `countries/${countryId}/states/${stateId}/cities`
    );
    return response.data || [];
  };

  return { getCountries, getStates, getCities };
};
