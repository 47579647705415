import { StyledIconButton } from "components/utils/buttons";
import { StyledHeader, StyledParagraph } from "components/utils/text";
import { Constants } from "config/constants";
import { BiLinkExternal } from "react-icons/bi";
import styled from "styled-components";

const colors = Constants.theme.colors;

const ListWrapper = styled.div`
  width: 100%;
  display: flex;
  max-height: 580px;
  height: 100%;
  flex-direction: column;

  p {
    margin: auto;
    font-weight: 800;
    color: ${colors.charcoalGrey};
    font-size: 1rem;
  }

  .content-wrapper {
    overflow-y: auto;
  }

  .header-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    flex-direction: row;
    justify-content: space-between;

    svg {
      font-size: 1.2rem;
    }

    .title {
      display: flex;
      align-items: center;
      flex-direction: row;
      svg {
        margin-right: 0.5rem;
      }
      h1 {
        font-size: 1.5rem;
        font-weight: 700;
      }
    }
  }
`;

const ListItem = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 20px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  background-color: ${colors.lightGrey};
  border: 1px solid ${colors.mediumGrey};
  border-radius: 10px;
  opacity: 0.9;
  margin-bottom: 0.5rem;

  .content {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;

    * {
      margin-right: 0.5rem;
    }
  }

  h1 {
    font-size: 1rem;
    font-weight: 600;
  }

  img {
    max-width: 30px;
    max-height: 30px;
    border-radius: 100px;
  }

  svg {
    font-size: 1.2rem;
  }

  p {
    text-align: center;
    font-weight: 600;
    color: ${(props) => props.color && props.color};
    background-color: ${(props) =>
      props.backgroundColor && props.backgroundColor};
    padding: 0.2rem 0.5rem;
    border-radius: 100px;
  }
`;

export const List = ({ data, title, icon }) => {
  return (
    <ListWrapper>
      <div className='header-wrapper'>
        <div className='title'>
          {icon}
          <StyledHeader>{title}</StyledHeader>
        </div>
        <StyledIconButton onClick={() => {}}>
          <BiLinkExternal />
        </StyledIconButton>
      </div>
      <ListWrapper className='content-wrapper'>
        {data.length > 0 ? (
          data.map((item, index) => {
            let image = item.image
              ? "https://ui-avatars.com/api/?name=" + item.title
              : null;
            return (
              <ListItem key={index} {...item.activity}>
                <div className='content'>
                  {item.image && <img src={image} alt='profile' />}
                  <StyledHeader>{item.title}</StyledHeader>
                  <StyledParagraph>{item.activity.text}</StyledParagraph>
                </div>
                <StyledIconButton onClick={item.activity.onClick}>
                  {item.activity.icon}
                </StyledIconButton>
              </ListItem>
            );
          })
        ) : (
          <StyledParagraph>No Activities</StyledParagraph>
        )}
      </ListWrapper>
    </ListWrapper>
  );
};
