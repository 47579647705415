import Fuse from "fuse.js";

export default function useFuse(data, setter, keys) {
  const options = {
    includeScore: true,
    includeMatches: true,
    threshold: 0.2,
    keys: keys,
  };

  const fuse = new Fuse(data, options);

  const handleSearch = (event) => {
    const { value } = event.target;

    if (value.length === 0) {
      setter(data);
      return;
    }

    const results = fuse.search(value);
    const items = results.map((result) => result.item);
    setter(items);
  };

  return { handleSearch };
}
