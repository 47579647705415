import Layout from "components/layout/layout";
import NotFound from "pages/utils/NotFound";
import Redirect from "pages/utils/Redirect";
import VerifyEmail from "pages/utils/verify-email";
import Welcome from "pages/pre-signup/welcome";
import { RequireAuth } from "./middleware";
import Admin from "pages/utils/Admin";
import Dashboard from "pages/dashboard";
import Pages from "pages/pages";
import Offers from "pages/offers";
import Orders from "pages/orders";
import Payout from "pages/payout";
import Account from "pages/account";
import Chat from "pages/chat";

export const routes = [
  {
    element: Layout,
    children: [
      {
        element: RequireAuth,
        children: [
          {
            path: "*",
            element: NotFound,
          },
          {
            path: "/",
            element: Dashboard,
          },
          {
            path: "/welcome",
            element: Welcome,
          },
          {
            path: "/verify-email",
            element: VerifyEmail,
          },
          {
            path: "/pages",
            element: Pages,
          },
          {
            path: "offers",
            element: Offers,
          },
          {
            path: "orders",
            element: Orders,
          },
          {
            path: "payout",
            element: Payout,
          },
          {
            path: "profile",
            element: Account,
          },
          {
            path: "chat",
            element: Chat,
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    element: Redirect,
  },
  {
    path: "/admin",
    element: Admin,
  },
];
