import { StyledIconButton, StyledSubmitButton } from "components/utils/buttons";
import { Constants } from "config/constants";
import styled from "styled-components";
import { RxExternalLink } from "react-icons/rx";

const colors = Constants.theme.colors;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;
  margin: 10px 0;
  background-color: ${colors.white};
  border-radius: 10px;

  h1 {
    margin-bottom: 10px;
  }

  h2 {
    margin-top: 20px;
    font-size: 1.2rem;
  }
`;

const PlanWrapper = styled.div`
  background-color: ${colors.lightGrey};
  border-radius: 10px;
  margin-top: 10px;
  padding: 10px;
  max-width: 600px;
  min-width: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    margin: 0;
    text-align: left;
  }

  .downgrade {
    margin-left: 10px;
    width: fit-content;
    margin-bottom: 0;
    padding: 5px 20px;
    background-color: ${colors.mediumGrey};
  }
`;

export const BillingSettings = () => {
  return (
    <>
      <Wrapper>
        <h1>My Plans</h1>
        <hr />
        <h2>Current Plan:</h2>
        <PlanWrapper>
          <h2>Pro Plan</h2>
          <StyledIconButton>
            <RxExternalLink />
          </StyledIconButton>
        </PlanWrapper>
        <h2>Other Plans:</h2>
        <PlanWrapper>
          <h2>Free Plan</h2>
          <StyledSubmitButton className='downgrade'>
            Switch to Free
          </StyledSubmitButton>
          <StyledIconButton>
            <RxExternalLink />
          </StyledIconButton>
        </PlanWrapper>
      </Wrapper>
      <Wrapper>
        <h1>Billing History</h1>
        <hr />
      </Wrapper>
    </>
  );
};
