import { BiLinkExternal } from "react-icons/bi";

//give me randome profile pictures from unsplash
export const ProfilePictures = [
  "https://images.unsplash.com/photo-1522075469751-3a6694fb2f61?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1480&q=80",
];

export const dummy = {
  user: {
    attribute: {
      emailConfirmationToken: null,
      reserPaswordToken: {
        token: null,
      },
      isBetaMember: false,
      last_token_refresh: null,
      isEmailVerified: false,
      isActive: false,
      isDeleted: false,
      isBlocked: false,
      isReported: false,
    },
    _id: "",
    Email: "",
    Password: "",
  },
};

export const TableConfig = [
  {
    name: "Name",
    key: "name",
    type: "text",
  },
  {
    name: "Status",
    key: "status",
    type: "status",
  },
  {
    name: "Date",
    key: "date",
    type: "date",
  },
  {
    name: "Amount",
    key: "amount",
    type: "text",
  },
];

export const TableData = [
  {
    id: 1,
    name: "John Doe",
    status: {
      text: "Delivered",
      backgroundColor: "lightgreen",
      color: "green",
    },
    date: new Date().toDateString(),
    amount: "$120.00",
    action: {
      text: "Details",
      onClick: () => {
        console.log("clicked");
      },
    },
  },
  {
    id: 2,
    name: "John Doe",
    status: {
      text: "Canceled",
      color: "white",
      backgroundColor: "red",
    },
    date: new Date().toDateString(),
    amount: "$120.00",
    action: {
      text: "Details",
      onClick: () => {
        console.log("clicked");
      },
    },
  },
  {
    id: 3,
    name: "John Doe",
    status: {
      text: "Pending",
      color: "orange",
      backgroundColor: "lightyellow",
    },
    date: new Date().toDateString(),
    amount: "$120.00",
    action: {
      text: "Details",
      onClick: () => {
        console.log("clicked");
      },
    },
  },
  {
    id: 3,
    name: "John Doe",
    status: {
      text: "Delivered",
      backgroundColor: "lightgreen",
      color: "green",
    },
    date: new Date().toDateString(),
    amount: "$120.00",
    action: {
      text: "Details",
      onClick: () => {
        console.log("clicked");
      },
    },
  },
  {
    id: 3,
    name: "John Doe",
    status: {
      text: "Delivered",
      backgroundColor: "lightgreen",
      color: "green",
    },
    date: new Date().toDateString(),
    amount: "$120.00",
    action: {
      text: "Details",
      onClick: () => {
        console.log("clicked");
      },
    },
  },
  {
    id: 3,
    name: "John Doe",
    status: {
      text: "Delivered",
      backgroundColor: "lightgreen",
      color: "green",
    },
    date: new Date().toDateString(),
    amount: "$120.00",
    action: {
      text: "Details",
      onClick: () => {
        console.log("clicked");
      },
    },
  },
  {
    id: 3,
    name: "John Doe",
    status: {
      text: "Delivered",
      backgroundColor: "lightgreen",
      color: "green",
    },
    date: new Date().toDateString(),
    amount: "$120.00",
    action: {
      text: "Details",
      onClick: () => {
        console.log("clicked");
      },
    },
  },
  {
    id: 3,
    name: "John Doe",
    status: {
      text: "Delivered",
      backgroundColor: "lightgreen",
      color: "green",
    },
    date: new Date().toDateString(),
    amount: "$120.00",
    action: {
      text: "Details",
      onClick: () => {
        console.log("clicked");
      },
    },
  },
];

export const ListData = [
  {
    id: 1,
    image: true,
    title: "John Doe",
    activity: {
      text: "New Message",
      backgroundColor: "lightgreen",
      color: "green",
      icon: <BiLinkExternal />,
      onClick: () => {
        console.log("clicked");
      },
    },
  },
  {
    id: 1,
    image: true,
    title: "John Doe",
    activity: {
      text: "New Message",
      backgroundColor: "lightgreen",
      color: "green",
      icon: <BiLinkExternal />,
      onClick: () => {
        console.log("clicked");
      },
    },
  },
  {
    id: 1,
    image: true,
    title: "John Doe",
    activity: {
      text: "New Message",
      backgroundColor: "lightgreen",
      color: "green",
      icon: <BiLinkExternal />,
      onClick: () => {
        console.log("clicked");
      },
    },
  },
  {
    id: 1,
    image: true,
    title: "John Doe",
    activity: {
      text: "New Message",
      backgroundColor: "lightgreen",
      color: "green",
      icon: <BiLinkExternal />,
      onClick: () => {
        console.log("clicked");
      },
    },
  },
  {
    id: 1,
    image: true,
    title: "John Doe",
    activity: {
      text: "New Message",
      backgroundColor: "lightgreen",
      color: "green",
      icon: <BiLinkExternal />,
      onClick: () => {
        console.log("clicked");
      },
    },
  },
  {
    id: 1,
    image: true,
    title: "John Doe",
    activity: {
      text: "New Message",
      backgroundColor: "lightgreen",
      color: "green",
      icon: <BiLinkExternal />,
      onClick: () => {
        console.log("clicked");
      },
    },
  },
  {
    id: 1,
    image: true,
    title: "John Doe",
    activity: {
      text: "New Message",
      backgroundColor: "lightgreen",
      color: "green",
      icon: <BiLinkExternal />,
      onClick: () => {
        console.log("clicked");
      },
    },
  },
  {
    id: 1,
    image: true,
    title: "John Doe",
    activity: {
      text: "New Message",
      backgroundColor: "lightgreen",
      color: "green",
      icon: <BiLinkExternal />,
      onClick: () => {
        console.log("clicked");
      },
    },
  },
  {
    id: 1,
    image: true,
    title: "John Doe",
    activity: {
      text: "New Message",
      backgroundColor: "lightgreen",
      color: "green",
      icon: <BiLinkExternal />,
      onClick: () => {
        console.log("clicked");
      },
    },
  },
];
