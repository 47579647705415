import { ContextWrapper } from "config/context";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { AppRoutes } from "routes";
import Menu from "components/sections/menu/menu";
import { Modal } from "components/utils/modal";

function App() {
  return (
    <ContextWrapper>
      <ToastContainer
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
        theme='light'
      />
      <Menu />
      <Modal />
      <AppRoutes />
    </ContextWrapper>
  );
}

export default App;
