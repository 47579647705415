import Chart from "chart.js/auto";
import { StyledIconButton } from "components/utils/buttons";
import { StyledHeader } from "components/utils/text";
import { Constants } from "config/constants";
import { useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import { BiLinkExternal } from "react-icons/bi";

const colors = Constants.theme.colors;

const StyledBoxChart = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  border-radius: 15px;
  max-height: 300px;
  flex-direction: column;

  .header-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    flex-direction: row;
    justify-content: space-between;

    svg {
      font-size: 1.2rem;
    }

    .title {
      display: flex;
      align-items: center;
      flex-direction: row;
      svg {
        margin-right: 0.5rem;
      }
      h1 {
        font-size: 1.5rem;
        font-weight: 700;
      }
    }
  }

  canvas {
    max-height: 200px;
    height: 100% !important;
    border: none;
  }
`;

const chartColors = [
  "#A1CCD1",
  "#E9B384",
  "#7C9D96",
  "#A2FF86",
  "#4FC0D0",
  "#A076F9",
];

export const BoxChart = ({ data, labels, title, type, icon }) => {
  const chartRef = useRef(null);

  const shortenTicks = useCallback(
    (value) => {
      let val = value;
      if (val >= 1000000) {
        val = (val / 1000000).toFixed(1) + " M";
      } else if (val >= 1000) {
        val = (val / 1000).toFixed(1) + " k";
      }
      if (type === "bar") {
        val = "$" + val;
      }
      return val;
    },
    [type]
  );

  useEffect(() => {
    const myChartRef = chartRef.current.getContext("2d");
    const chart = new Chart(myChartRef, {
      type: type,
      data: {
        labels: labels,
        datasets: [
          {
            label: title,
            data: data,
            barPercentage: 0.8, // Adjust bar width as a percentage of available space
            borderRadius: 5,
            borderWidth: type === "bar" ? 0 : 3,
            borderSkipped: false,
            borderColor: chartColors[1],
            backgroundColor: type === "bar" ? chartColors : chartColors[1],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              color: colors.darkGrey,
              callback: function (val, index) {
                return index % 2 === 0 ? shortenTicks(val) : "";
              },
            },
            grid: {
              display: false,
              drawBorder: false,
            },
            border: {
              display: false,
            },
          },
          x: {
            ticks: {
              color: colors.darkGrey,
            },
            border: {
              display: false,
            },
            grid: {
              display: false,
            },
          },
        },
        elements: {
          point: {
            radius: 1,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                return shortenTicks(context.raw);
              },
            },
          },
        },
      },
    });
    return () => {
      chart.destroy();
    };
  }, [data, labels, shortenTicks, title, type]);

  return (
    <StyledBoxChart>
      <div className='header-wrapper'>
        <div className='title'>
          {icon}
          <StyledHeader>{title}</StyledHeader>
        </div>
        <StyledIconButton onClick={() => {}}>
          <BiLinkExternal />
        </StyledIconButton>
      </div>
      <canvas id='myChart' ref={chartRef} />
    </StyledBoxChart>
  );
};
