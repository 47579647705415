import { Input } from "components/utils/inputs";
import styled from "styled-components";

import { Constants } from "config/constants";

const colors = Constants.theme.colors;

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;
  margin: 10px 0;
  justify-content: start;
  background-color: ${colors.white};
  border-radius: 10px;

  * {
    margin-top: 10px;
    max-width: 600px;
    min-width: 300px;
  }

  .info-img {
    width: 100%;
    max-width: 250px;
    margin: 0 auto;
  }
`;

export const StyledFormSelect = styled.button`
  display: flex;
  flex-direction: row;
  margin: 15px 0;
  justify-content: center;
  width: 100%;
  height: 100px;
  border-radius: 10px;
  padding: 10px;
  background-color: ${colors.lightGrey};
  align-items: center;
  cursor: pointer;
  user-select: none;
  border: none;

  &:hover {
    background-color: ${colors.mediumGrey};
  }

  &:active {
    opacity: 0.8;
    outline: none;
    transform: scale(0.98);
    transition: transform 0.1s ease-in-out;
  }

  h3 {
    margin: 0;
    font-size: 1.8rem;
    font-weight: 350;
    color: ${colors.charcoalGrey};
  }

  .icon {
    margin-right: 10px;
    font-size: 2rem;
    color: ${colors.primary};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const FormInput = (props) => {
  return (
    <Input {...props} required={props.required || true} margin={"15px 0 0 0"} />
  );
};
