import styled from "styled-components";
import { AiOutlineCheck } from "react-icons/ai";

import { Constants } from "../../config/constants";

const colors = Constants.theme.colors;

export const StyledTextArea = styled.textarea`
  border: none;
  padding: 8px 12px;
  font-size: ${(props) => props.fontSize || "1rem"};
  font-weight: ${(props) => props.fontWeight || "500"};
  background: ${colors.lightGrey};
  height: 150px;
  border-radius: 10px;
  color: ${colors.darkGrey};
  margin-bottom: 10px;
  margin: ${(props) => props.margin};
  resize: none;

  &:focus {
    transition: 0.2s ease-out;
    outline: none;
    border: 2px solid ${colors.primary};
  }
`;

export const StyledInput = styled.input`
  border: none;
  padding: 15px 12px;
  font-size: ${(props) => props.fontSize || "0.9rem"};
  font-weight: ${(props) => props.fontWeight || "500"};
  background: ${colors.lightGrey};
  border-radius: 10px;
  hint: ${colors.darkGrey};
  color: ${colors.charcoalGrey};
  margin-bottom: 10px;
  margin: ${(props) => props.margin};

  &:focus {
    transition: 0.2s ease-out;
    outline: none;
    border: 2px solid ${colors.primary};
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CustomCheckboxWrapper = styled.label`
  display: inline-block;
  min-width: 25px;
  height: 25px;
  position: relative;
  border-radius: 8px;
  border: 1px solid ${colors.mediumGrey};
  overflow: hidden;
  margin: 5px 0;
  margin: ${(props) => props.margin};
`;

const CheckboxInput = styled.input.attrs({ type: "checkbox" })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const CheckboxBackground = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  font-size: 1.1rem;
  justify-content: center;
  color: ${({ checked }) => (checked ? "white" : "transparent")};
  align-items: center;
  background-color: ${({ checked }) => (checked ? "#81bbff" : "transparent")};
`;

const CheckboxLabel = styled.label`
  font-size: 0.9rem;
  color: ${colors.darkGrey};
  margin-left: 10px;
  font-weight: 500;
`;

export const StyledCheckbox = (props) => {
  const handleCheckboxChange = () => {
    props.setChecked(!props.checked);
  };

  return (
    <Row>
      <CustomCheckboxWrapper {...props}>
        <CheckboxInput
          {...props}
          checked={props.checked}
          onChange={handleCheckboxChange}
        />
        <CheckboxBackground {...props} checked={props.checked}>
          <AiOutlineCheck />
        </CheckboxBackground>
      </CustomCheckboxWrapper>
      <CheckboxLabel {...props}>{props.label}</CheckboxLabel>
    </Row>
  );
};

export const Input = (props) => {
  const { onChange, value } = props;
  return (
    <StyledInput
      {...props}
      onChange={onChange}
      disabled={props.disabled || false}
      value={value}
      required={props.required || true}
      margin={"15px 0 0 0"}
    />
  );
};

const SelectContainer = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
`;

const SelectField = styled.select`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
`;

export const CustomSelect = ({ label, options, value, onChange }) => {
  return (
    <SelectContainer>
      <Label>{label}</Label>
      <SelectField value={value} onChange={onChange}>
        <option value=''>Select an option</option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </SelectField>
    </SelectContainer>
  );
};
