import { BillingSettings } from "components/sections/profile/billing";
import { NotificationSettings } from "components/sections/profile/notifications";
import { Profile } from "components/sections/profile/profile";
import { SettingsSection } from "components/sections/profile/settings";
import TabBar from "components/sections/tab-bar/Tabbar";
import { useState, useEffect } from "react";

function Account() {
  const tabs = ["Profile", "Settings", "Notification Settings", "Billing"];
  const location = Number.parseInt(window.location.hash.slice(1));
  if (isNaN(location)) window.location.hash = "";
  const [activeTab, setActiveTab] = useState(
    Number.parseInt(window.location.hash.slice(1)) || 0
  );
  useEffect(() => {
    const interval = setInterval(() => {
      if (isNaN(location)) window.location.hash = "";
      setActiveTab(Number.parseInt(window.location.hash.slice(1)) || 0);
    });

    return () => {
      clearInterval(interval);
    };
  }, [activeTab, location]);

  return (
    <div>
      <TabBar tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
      {activeTab === 0 && (
        <Profile
          handleSubmit={(values) => {
            console.log(values);
          }}
        />
      )}
      {activeTab === 1 && (
        <SettingsSection
          handleSubmit={(values) => {
            console.log(values);
          }}
        />
      )}
      {activeTab === 2 && (
        <NotificationSettings
          handleSubmit={(values) => {
            console.log(values);
          }}
        />
      )}
      {activeTab === 3 && (
        <BillingSettings
          handleSubmit={(values) => {
            console.log(values);
          }}
        />
      )}
    </div>
  );
}

export default Account;
