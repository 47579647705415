import { Constants } from "config/constants";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const colors = Constants.theme.colors;

const SelectContainer = styled.div`
  position: relative;
  margin-top: 0;
`;

const StyledSelect = styled.div`
  appearance: none;
  padding: 10px;
  border-radius: 4px;
  width: 100%;
  background-color: #f5f5f5;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, transparent, transparent);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 3.5em;
  background-repeat: no-repeat;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

const OptionsContainer = styled.ul`
  position: absolute;
  max-height: 300px;
  overflow: scroll;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0;
  list-style: none;
  margin: 0;
  opacity: ${({ showOptions }) => (showOptions ? "1" : "0")};
  transform: ${({ showOptions }) =>
    showOptions ? "translateY(0)" : "translateY(-10px)"};
  transition: opacity 0.2s, transform 0.2s;
  z-index: ${({ showOptions }) => (showOptions ? "1" : "-100")};
`;

const Option = styled.li`
  padding: 8px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export const CustomSelect = ({ label, options, value, onChange, disabled }) => {
  const [showOptions, setShowOptions] = useState(false);
  const selectRef = useRef(null);

  const handleSelectClick = () => {
    setShowOptions(!showOptions);
  };

  const handleOptionClick = (optionValue) => {
    onChange(optionValue);
    setShowOptions(false);
  };

  const handleDocumentClick = (e) => {
    if (selectRef.current && !selectRef.current.contains(e.target)) {
      setShowOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleDocumentClick);
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, []);

  return (
    <SelectContainer ref={selectRef}>
      <StyledSelect
        onClick={disabled ? null : handleSelectClick}
        onBlur={() => setShowOptions(false)}
      >
        {value || label}
      </StyledSelect>
      <OptionsContainer showOptions={showOptions}>
        {options.map((option, index) => (
          <Option key={index} onClick={() => handleOptionClick(option)}>
            {option}
          </Option>
        ))}
      </OptionsContainer>
    </SelectContainer>
  );
};
