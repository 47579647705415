import { useCustomToast } from "hooks/toast";
import React, { useContext, useEffect, useState } from "react";
import { useApi } from "hooks/api";
import { useUtils } from "hooks/utils";
import { dummy } from "./dummy";
import { useNavigate } from "react-router";

const Context = React.createContext();

export const ContextWrapper = ({ children }) => {
  const navigate = useNavigate();
  const [modalContent, setModalContent] = useState({
    open: false,
    title: "hello",
    content: null,
  });

  const [sidebar, setSidebar] = useState(false);
  const { showToast } = useCustomToast();
  const [loading, setLoading] = useState(true);
  const [signedIn, setSignedIn] = useState(false);
  const [user, setUser] = useState(dummy.user);
  const api = useApi({ showToast });
  const [menu, setMenu] = useState({ state: false, location: "top" });
  const utils = useUtils();

  const redirect = (url) => {
    window.open(url, "_blank");
  };

  useEffect(() => {
    if (!signedIn) {
      api.checkAdmin().then((data) => {});
      api.resetToken().then((data) => {
        setSignedIn(data.status);
      });
      api.getUser().then((data) => {
        if (data.status) {
          setUser(data.data);
          setLoading(false);
          if (!data.data.attribute.isEmailVerified) {
            navigate("/verify-email");
          }
        }
      });
    }
  }, [loading, api, user, navigate, signedIn]);

  const reset = () => {
    setLoading(true);
    setSignedIn(false);
  };

  const toggleSidebar = () => {
    setSidebar(!sidebar);
  };

  const toggleMenu = (location) => {
    setMenu({
      state: true,
      location: location,
    });
  };

  const contextData = {
    redirect,
    showToast,
    api,
    loading,
    setLoading,
    utils,
    user,
    signedIn,
    reset,
    sidebar,
    toggleSidebar,
    menu,
    setMenu,
    toggleMenu,
    modalContent,
    setModalContent,
  };

  return <Context.Provider value={contextData}>{children}</Context.Provider>;
};

export const usePlatformContext = () => {
  return useContext(Context);
};
