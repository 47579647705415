import styled from "styled-components";
import { Constants } from "config/constants";
import useFuse from "hooks/fuse";

const colors = Constants.theme.colors;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  input {
    flex: 1;
    border: none;
    outline: none;
    font-size: 1rem;
    font-weight: 500;
    color: ${colors.darkGrey};
  }
`;

export const SearchField = (props) => {
  const { handleSearch } = useFuse(props.data, props.setter, props.keys);
  return (
    <Wrapper>
      <input
        type='text'
        placeholder='Search'
        onChange={(e) => {
          handleSearch(e);
        }}
      />
    </Wrapper>
  );
};
