import { ClipLoader } from "react-spinners";
import { Constants } from "config/constants";
const colors = Constants.theme.colors;

export const Loading = () => {
  return (
    <ClipLoader
      size={150}
      className='loader'
      color={colors.primary}
      aria-label='Loading Spinner'
      data-testid='loader'
    />
  );
};
