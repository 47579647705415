import { FormWrapper } from "components/utils/form.styles";
import { StyledSubmitButton } from "components/utils/buttons";
import { Input } from "components/utils/inputs";

export const SettingsSection = () => {
  const handleClick = () => {
    console.log("clicked");
  };
  return (
    <>
      <FormWrapper
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          handleClick();
        }}
      >
        <h2>Change Password</h2>
        <Input placeholder='New Password' type='text' />
        <Input placeholder='Confirm new Password' type='text' />
        <Input placeholder='Old Password' type='password' />
        <StyledSubmitButton type='submit'>Change Password</StyledSubmitButton>
      </FormWrapper>
      <FormWrapper
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          handleClick();
        }}
      >
        <h2>Change Email</h2>
        <Input placeholder='New Email' type='text' />
        <StyledSubmitButton type='submit'>
          Verify new Email per mail
        </StyledSubmitButton>
      </FormWrapper>
    </>
  );
};
