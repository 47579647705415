import styled from "styled-components";
import { DynamicTable } from "./table";
import { TableFilter } from "./filter";
import { TableData } from "config/dummy";
import { useState } from "react";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const OrdersLayer = () => {
  const [filtered, setFiltered] = useState(TableData);

  return (
    <Wrapper>
      <TableFilter data={TableData} setter={setFiltered} />
      <DynamicTable unlimited={true} noheader={true} data={filtered} />
    </Wrapper>
  );
};
