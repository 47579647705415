import styled from "styled-components";
import { useState } from "react";

import { Constants } from "config/constants";

const colors = Constants.theme.colors;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;
  margin: 10px 0;
  background-color: ${colors.white};
  border-radius: 10px;
`;

export const SettingsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;

  thead {
    background-color: ${colors.lightGrey};
    color: ${colors.charcoalGrey};
    font-weight: 500;
  }

  th,
  td {
    padding: 12px 15px;

    &:first-child {
      text-align: left;
      flex: 1;
    }
  }

  tbody tr {
    border-bottom: 1px solid ${colors.lightGrey};
  }

  tbody tr:last-child {
    border-bottom: none;
  }

  tbody tr:nth-child(even) {
    background-color: ${colors.white};
  }
`;

const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  cursor: pointer;
`;

const CustomCheckbox = styled.span`
  width: 20px;
  height: 20px;
  border: 2px solid #333;
  border-radius: 4px;
  margin-right: 10px;
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ checked }) => (checked ? "#007bff" : "transparent")};
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  position: absolute;
  opacity: 0;

  &:checked + ${CustomCheckbox} {
    background-color: #007bff;
    border-color: #007bff;
  }
`;

const CustomInput = ({ label, type, value, onChange }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <CheckboxContainer>
      <HiddenCheckbox checked={isChecked} onChange={handleCheckboxChange} />
      <CustomCheckbox checked={isChecked}>✓</CustomCheckbox>
    </CheckboxContainer>
  );
};

export const NotificationSettings = () => {
  const [notificationSettings, setNotificationSettings] = useState([
    {
      type: "Order Confirmation",
      email: false,
      push: false,
    },
    {
      type: "Promotion Recommendations",
      email: false,
      push: false,
    },
    {
      type: "Profile Updates",
      email: false,
      push: false,
    },
    {
      type: "New Order Requests",
      email: false,
      push: false,
    },
    {
      type: "Order Progress Updates",
      email: false,
      push: false,
    },
    {
      type: "Upcoming Promotions",
      email: false,
      push: false,
    },
    {
      type: "Chat Messages",
      email: false,
      push: false,
    },
    {
      type: "Earnings and Payouts",
      email: false,
      push: false,
    },
    {
      type: "Recommended Promotions",
      email: false,
      push: false,
    },
    {
      type: "Feedback and Reviews",
      email: false,
      push: false,
    },
    {
      type: "New Platform Features",
      email: false,
      push: false,
    },
    {
      type: "Exclusive Offers and Discounts",
      email: false,
      push: false,
    },
    {
      type: "Content Insights",
      email: false,
      push: false,
    },
  ]);

  const handleEmailToggle = (index) => {
    const updatedSettings = [...notificationSettings];
    updatedSettings[index].email = !updatedSettings[index].email;
    setNotificationSettings(updatedSettings);
  };

  const handlePushToggle = (index) => {
    const updatedSettings = [...notificationSettings];
    updatedSettings[index].push = !updatedSettings[index].push;
    setNotificationSettings(updatedSettings);
  };

  return (
    <Wrapper>
      <h1>Notification Settings</h1>
      <SettingsTable>
        <thead>
          <tr>
            <th>Notification Type</th>
            <th>Email Notification</th>
            <th>Push Notification</th>
          </tr>
        </thead>
        <tbody>
          {notificationSettings.map((setting, index) => (
            <tr key={index}>
              <td>{setting.type}</td>
              <td>
                <CustomInput
                  type='checkbox'
                  checked={setting.email}
                  onChange={() => handleEmailToggle(index)}
                  label='Email Notification'
                />
              </td>
              <td>
                <CustomInput
                  type='checkbox'
                  checked={setting.push}
                  onChange={() => handlePushToggle(index)}
                  label='Push Notification'
                />
              </td>
            </tr>
          ))}
        </tbody>
      </SettingsTable>
    </Wrapper>
  );
};
