import {
  LayoutContent,
  LayoutFooter,
  LayoutHeader,
  LayoutMain,
  LayoutWrapper,
} from "components/layout/layout.styles";
import React from "react";
import { Outlet } from "react-router";
import { Loading } from "components/utils/loading";
import { usePlatformContext } from "config/context";
import { Header } from "./header";
import { Footer } from "./footer";
import { Sidebar } from "./sidebar";

function Layout() {
  const context = usePlatformContext();
  return (
    <LayoutWrapper>
      <Sidebar />
      <LayoutMain>
        <LayoutHeader sidebar={context.sidebar}>
          <Header />
        </LayoutHeader>
        <LayoutContent normal={!context.loading}>
          {context.loading ? <Loading /> : <Outlet />}
        </LayoutContent>
        <LayoutFooter>
          <Footer />
        </LayoutFooter>
      </LayoutMain>
    </LayoutWrapper>
  );
}

export default Layout;
