import styled from "styled-components";

export const DashbaordGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;
  margin: 20px;

  .element {
    max-height: 300px;
    grid-column: span 2;
  }

  .wide {
    grid-column: span 6;
  }

  @media (max-width: ${(props) => (props.sidebar ? "1200px" : "900px")}) {
    .element {
      grid-column: span 3;
      max-height: fit-content;

      &:nth-child(2) {
        grid-row-start: 2;
      }
    }

    .last {
      grid-row: span 2 !important;
      grid-row-start: 1;
    }
  }

  @media (max-width: 1000px) {
    .element {
      grid-column: span 6 !important;
    }
  }

  @media (max-width: 768px) {
    margin: 20px 0;
  }
`;
