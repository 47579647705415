import { DashbaordGrid } from "components/grids/dashboard.grid";
import { DashboardCard } from "components/utils/cards";
import { VscGraph } from "react-icons/vsc";
import { IoPeopleOutline } from "react-icons/io5";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { DynamicTable } from "./table/table";
import { usePlatformContext } from "config/context";
import { BoxChart } from "components/charts/box-chart";
import { List } from "./list/list";
import { TableData } from "config/dummy";

export const DashboardLayer = () => {
  const randomNumbers = () => {
    const arr = [];
    for (let i = 0; i < 11; i++) {
      arr.push(Math.floor(Math.random() * 450000) + 50000);
    }
    return arr;
  };
  const context = usePlatformContext();
  return (
    <DashbaordGrid sidebar={context.sidebar}>
      <DashboardCard className='element'>
        <BoxChart
          data={randomNumbers()}
          labels={[
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ]}
          title='Revenue'
          type='bar'
          header={"Revenue"}
          icon={<VscGraph />}
        />
      </DashboardCard>
      <DashboardCard className='element'>
        <BoxChart
          data={randomNumbers()}
          labels={[
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ]}
          title='Visitors'
          type='line'
          header={"Revenue"}
          icon={<IoPeopleOutline />}
        />
      </DashboardCard>
      <DashboardCard className='element last'>
        <List
          data={[]}
          title='Activities'
          icon={<MdOutlineNotificationsActive />}
        />
      </DashboardCard>
      <DynamicTable className='wide' data={TableData} />
    </DashbaordGrid>
  );
};
