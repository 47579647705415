import { Constants } from "config/constants";
import styled from "styled-components";

const colors = Constants.theme.colors;

export const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  height: 100vh;
`;

export const LayoutFooter = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  text-align: center;
  padding: 20px;
  height: auto;
`;

export const HeaderElement = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 1300px;
  width: ${(props) => props.justify === "space-between" && "100%"};
  height: auto;
  justify-content: ${(props) => (props.justify ? props.justify : "start")};

  .credits {
    font-size: 0.9rem;
    font-weight: 800;
    color: ${colors.charcoalGrey};
    margin-right: 1rem;
  }

  .title {
    font-size: 2.2rem;
    font-weight: 800;
  }

  .header-wrapper {
    flex: 1;
    display: flex;
    width: fit-content;
    height: auto;
    flex-direction: column;
  }

  .logo {
    display: none;
  }

  @media (max-width: 768px) {
    .title {
      font-size: 1.5rem;
    }

    .header-wrapper {
      p {
        font-size: 0.7rem;
      }
    }
  }

  @media (max-width: 500px) {
    .header-wrapper {
      display: none;
    }
    .logo {
      display: block;
    }
  }
`;

export const LayoutHeader = styled.div`
  display: flex;
  padding: 0 3rem;
  position: fixed;
  z-index: 100;
  width: calc(100% - ${(props) => (props.sidebar ? 305 : 105)}px);
  height: 130px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${colors.lightGrey};

  @media (max-width: 768px) {
    width: calc(100% - 105px);
  }

  @media (max-width: 500px) {
    width: calc(100% - 5px);
  }
`;

export const LayoutMain = styled.div`
  display: flex;
  flex: 1;
  min-height: 100vh;
  flex-direction: column;
  overflow: auto;
  align-items: center;
  padding: 0 3rem;
  background-color: ${colors.lightGrey};
`;

export const LayoutContent = styled.div`
  display: flex;
  max-width: 1300px;
  flex: 1;
  flex-direction: column;
  justify-content: ${(props) => (props.normal ? "start" : "center")};
  margin: 0 auto;
  width: 100%;
  margin-top: 150px;
`;
