import { usePlatformContext } from "config/context";
import { useEffect, useRef } from "react";
import styled from "styled-components";
import { StyledIconButton } from "./buttons";
import { AiOutlineClose } from "react-icons/ai";
import { StyledHeader } from "./text";

const PageWrapper = styled.div`
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: ${(props) => (props.show ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: ${(props) => (props.show ? "1000" : "-1000")};

  .modal {
    background-color: white;
    padding: 1rem;
    border-radius: 8px;
  }

  .modal-header {
    min-width: 300px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    h1 {
      font-size: 1.3rem;
      font-weight: 600;
    }
  }
`;

export const Modal = () => {
  const context = usePlatformContext();
  let modalRef = useRef(null);

  useEffect(() => {
    let handler = (e) => {
      if (!modalRef.current.contains(e.target)) {
        context.setModalContent({ title: null, body: null });
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <PageWrapper show={context.modalContent.open}>
      <div className='modal' ref={modalRef}>
        <div className='modal-header'>
          <StyledHeader>{context.modalContent.title}</StyledHeader>
          <StyledIconButton
            onClick={() => {
              context.setModalContent({ open: false });
            }}
          >
            <AiOutlineClose />
          </StyledIconButton>
        </div>
        {context.modalContent.content || <div>Nothing Here</div>}
      </div>
    </PageWrapper>
  );
};
