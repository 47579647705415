import { StyledLink, StyledParagraph } from "components/utils/text";
import styled from "styled-components";

const FooterContainer = styled.div`
  display: flex;
  max-width: 1300px;
  width: 100%;
  text-align: left;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 500px) {
    align-items: start;
    flex-direction: column;
  }
`;

const Links = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;

  a {
    text-align: start;
    margin-right: 10px;
  }

  @media (max-width: 500px) {
    a {
      margin: 0;
      margin-bottom: 10px;
    }

    flex-direction: column;
  }
`;

export const Footer = () => {
  return (
    <FooterContainer>
      <StyledParagraph>
        ©{new Date().getFullYear()} Bestgrowth. All rights reserved.
      </StyledParagraph>
      <Links>
        <StyledLink to='/'>Our Terms</StyledLink>
        <StyledLink to='/'>Privacy</StyledLink>
      </Links>
    </FooterContainer>
  );
};
