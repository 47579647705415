import { Constants } from "config/constants";
import React from "react";
import styled from "styled-components";

const colors = Constants.theme.colors;

const TabBarContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${colors.lightGrey};
  padding: 10px 0;
  border-bottom: 1px solid ${colors.mediumGrey};
`;

const Tab = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  background-color: ${({ active }) =>
    active ? colors.primary : "transparent"};
  color: ${({ active }) => (active ? "#ffffff" : "#333333")};
  border-radius: 5px;
  margin-right: 15px;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${({ active }) => (active ? "#0056b3" : "#e0e0e0")};
  }
`;

const TabBar = ({ tabs, activeTab, setActiveTab }) => {
  return (
    <TabBarContainer>
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          active={activeTab === index}
          onClick={() => {
            setActiveTab(index);
            window.location.hash = index;
          }}
        >
          {tab}
        </Tab>
      ))}
    </TabBarContainer>
  );
};

export default TabBar;
