import { DashboardLayer } from "components/sections/dashboard";

function Dashboard() {
  return (
    <div>
      <DashboardLayer />
    </div>
  );
}

export default Dashboard;
