import styled from "styled-components";
import { Constants } from "config/constants";
import { BiLinkExternal } from "react-icons/bi";
import { BsBagCheck } from "react-icons/bs";
import {
  StyledHeader,
  StyledLink,
  StyledParagraph,
} from "components/utils/text";
import { StyledIconButton, StyledSubmitButton } from "components/utils/buttons";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { TableData } from "config/dummy";
import { TableConfig } from "config/dummy";
import { useState } from "react";

const colors = Constants.theme.colors;

const TableWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
  min-height: ${(props) => (props.unlimited ? 0 : "400px")};
  background-color: ${colors.white};
  border-radius: 15px;
  padding: 20px;
  border: 1px solid ${colors.mediumGrey};

  .header-wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 25px;

    svg {
      font-size: 1.2rem;
    }

    .title {
      display: flex;
      align-items: center;
      flex-direction: row;
      svg {
        margin-right: 0.5rem;
      }
      h1 {
        font-size: 1.5rem;
        font-weight: 700;
      }
    }
  }

  .table-wrapper {
    overflow-x: scroll;
  }

  hr {
    height: 0.5px;
    background-color: ${colors.mediumGrey};
    border: none;
    margin-bottom: 15px;
  }
`;

const Table = styled.table`
  width: 100%;
  height: auto;
  border-collapse: collapse;
  max-height: 280px;

  thead tr {
    border: none;
    td {
      font-weight: 400;
      color: ${colors.charcoalGrey};
    }
  }

  tbody {
    font-weight: 500;
  }

  tr {
    border-top: 1px solid ${colors.lightGrey};
  }

  td {
    height: 50px;
    padding: 0 10px;

    .table-button {
      width: fit-content;
      padding: 8px 12px;
      font-size: 0.8rem;
      font-weight: 700;
      background-color: transparent;
      margin: 0;
      height: auto;
      text-decoration: none;
      color: ${colors.charcoalGrey};
      border: 1.5px solid ${colors.mediumGrey};
      border-radius: 100px;

      &:hover {
        background-color: ${colors.lightGrey};
      }

      &:active {
        background-color: ${colors.mediumGrey};
      }
    }

    .status {
      padding: 8px 12px;
      border-radius: 100px;
      width: fit-content;
    }

    @media (max-width: 768px) {
      min-width: 100px;
    }
  }
`;

const TableSwitcher = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: end;
  flex: 1;

  .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

const mapContent = (type, data) => {
  let result = <></>;
  switch (type) {
    case "image":
      result = <img src={data.src} alt={data.alt} />;
      break;
    case "link":
      result = <StyledLink to={data.to}>{data.text}</StyledLink>;
      break;
    case "button":
      result = (
        <StyledSubmitButton className='table-button' onClick={data.onClick}>
          {data.text}
        </StyledSubmitButton>
      );
      break;
    case "status":
      result = (
        <StyledParagraph
          className='status'
          style={{ color: data.color, backgroundColor: data.backgroundColor }}
        >
          {data.text}
        </StyledParagraph>
      );
      break;
    default:
      result = <p>{data}</p>;
      break;
  }
  return result;
};

const TableContent = (props) => {
  const { type, data } = props;
  let element = mapContent(type, data);
  return <td>{element}</td>;
};

export const DynamicTable = (props) => {
  let maxCols = props.unlimited ? TableData.length : 3;
  let totalPages = TableData.length / maxCols;
  const [page, setPage] = useState(1);

  const forward = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const backward = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  return (
    <TableWrapper className={props.className} unlimited={props.unlimited}>
      {props.noheader || (
        <>
          <div className='header-wrapper'>
            <div className='title'>
              <BsBagCheck />
              <StyledHeader>Your Orders</StyledHeader>
            </div>
            <StyledIconButton onClick={() => {}}>
              <BiLinkExternal />
            </StyledIconButton>
          </div>
          <hr />
        </>
      )}
      <div className='table-wrapper'>
        <Table>
          <thead>
            <tr className='header'>
              {TableConfig.map((config, index) => {
                return <td key={index}>{config.name}</td>;
              })}
              <td></td>
            </tr>
          </thead>
          <tbody>
            {props.data
              .slice((page - 1) * maxCols, page * maxCols)
              .map((item, index) => {
                return (
                  <tr key={index}>
                    {TableConfig.map((config, index) => {
                      return (
                        <TableContent
                          key={index}
                          type={config.type}
                          data={item[config.key]}
                        />
                      );
                    })}
                    <TableContent type={"button"} data={item.action} />
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>

      {props.unlimited || (
        <TableSwitcher>
          <div className='wrapper'>
            <StyledIconButton onClick={backward}>
              <IoIosArrowBack />
            </StyledIconButton>
            <p>
              {page} of {Math.ceil(totalPages)}
            </p>
            <StyledIconButton onClick={forward}>
              <IoIosArrowForward />
            </StyledIconButton>
          </div>
        </TableSwitcher>
      )}
    </TableWrapper>
  );
};
