import styled from "styled-components";
import { Constants } from "../../config/constants";
import { IconButton } from "@mui/material";
import { usePlatformContext } from "config/context";
import { StyledLink } from "./text";

const colors = Constants.theme.colors;

export const StyledSubmitButton = styled.button`
  width: 100%;
  border: none;
  padding: 8px 12px;
  font-size: ${(props) => props.fontSize || "1rem"};
  font-weight: ${(props) => props.fontWeight || "500"};
  background: ${colors.primary};
  height: ${(props) => props.height || "50px"};
  border-radius: 1000px;
  color: ${colors.white};
  margin-bottom: 10px;
  margin: ${(props) => props.margin};
  cursor: pointer;
  transition: 0.2s ease-out;

  &:hover {
    background: ${(props) => props.background || colors.primary};
    opacity: 0.9;
    transition: 0.2s ease-out;
  }

  &:active {
    transition: 0.2s ease-out;
    outline: none;
  }

  &:disabled {
    background: ${colors.mediumGrey};
    color: ${colors.charcoalGrey};
    cursor: not-allowed;
  }
`;

export const StyledIconButton = styled(IconButton)`
  color: ${colors.primary};
  margin-left: auto;
  margin-right: 0;
  padding: 0;

  &:hover {
    background: ${colors.lightGrey};
    border-radius: 1000px;
    transition: 0.2s ease-out;
  }
`;

export const CustomSidebarLink = (props) => {
  const context = usePlatformContext();
  return (
    <StyledLink
      {...props}
      onClick={() => {
        context.sidebar && context.toggleSidebar();
        props.onClick && props.onClick();
      }}
    />
  );
};
