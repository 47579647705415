import React from "react";
import { Container } from "components/utils/wrapper";
import { StyledLink } from "components/utils/text";

function NotFound() {
  return (
    <Container>
      <h1>404</h1>
      <p>Page not found</p>
      <StyledLink
        textAlign={"center"}
        color='#7b7b7b'
        to='/'
        reloadDocument={true}
      >
        Go Back
      </StyledLink>
    </Container>
  );
}

export default NotFound;
